<template>
    <div class="CooperativeEnterpriseView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="单号"
                    placeholder="单号"
                />
                <select-component
                    name="state"
                    lable="审核状态"
                    :farr="this.$store.state.state"
                    @clk="confirm_state"
                ></select-component>
                <van-field
                    v-model="cond.enterprise_name"
                    name="enterprise_name"
                    label="企业名称"
                    placeholder="企业名称"
                />
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.enterprise_name}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="单号" :value="v.name" :border="false" />
                <van-cell class="note_cell" title="合作状态" :border="false">
                    <template #right-icon>
                        <van-tag plain type="danger" v-if="v.status===0">禁用</van-tag>
                        <van-tag plain type="warning" v-if="v.status===1">临时</van-tag>
                        <van-tag plain type="success" v-if="v.status===2">长期</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="是否签合同" :border="false">
                    <template #right-icon>
                        <van-tag round type="danger" v-if="v.is_cooperation===0">否</van-tag>
                        <van-tag round type="primary" v-if="v.is_cooperation===1">是</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="申请人" :value="v.staff_name" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { cooperative_enterprise_index_request } from '@/network/business/CooperativeEnterprise.js'

export default {
    name:'CooperativeEnterpriseView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '合作企业管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                state: '',
                enterprise_name: '',
                page: 1
            }
        }
    },
    computed:{},
    methods:{
        get_cooperative_enterprise() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            cooperative_enterprise_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_state(e) {
            this.cond.state = e.id
        },
        search() {
            this.cond.page = 1
            this.get_cooperative_enterprise()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                state: '',
                enterprise_name: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('合作企业申请详情')) {
                this.$router.push({
                    name: 'CooperativeEnterpriseDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                cooperative_enterprise_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_cooperative_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.CooperativeEnterpriseView .van-cell-group {
    margin-bottom: 0.2rem;
}
.CooperativeEnterpriseView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.CooperativeEnterpriseView .note_cell {
    padding: 0.1rem 0.42667rem
}
.CooperativeEnterpriseView .search_title {
    text-align: center;
}
.CooperativeEnterpriseView .search_button {
    margin: 0.5rem;
}
.CooperativeEnterpriseView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>